import { ActionType } from "typesafe-actions";
import * as ProductAction from "./ProductAction";

type Actions = typeof ProductAction;

export type ProductActions = ActionType<Actions>;

export * from "./Product.types";
export * from "./ProductAction";
export * from "./ProductReducer";
