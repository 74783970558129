import { ActionType } from "typesafe-actions";
import * as CallReportAction from "./CallReportAction";

type Actions = typeof CallReportAction;

export type CallReportActions = ActionType<Actions>;
export type CallReportDetailsActions = ActionType<Actions>;

export * from "./CallReport.types";
export * from "./CallReportAction";
export * from "./CallReportReducer";
