import html2canvas from "html2canvas";

export const downloadMembershipCard = (element: HTMLElement) => {
  html2canvas(element, {
    onclone: function (clonedDoc) {
      console.log(clonedDoc.getElementById(element.id));
      //@ts-ignore
      clonedDoc.getElementById(element.id).style.display = "block";
    },
  }).then((canvas) => {
    const link = document.createElement("a");
    link.download = "membership-card.png";
    link.href = canvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
